import Vue from 'vue';
import Router from 'vue-router';
import EnergyServicesApi from "@/views/EnergyServicesApi";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/energiapalvelut',
      name: 'energy_services',
      component: EnergyServicesApi,
    },
    {
      path: '*',
      redirect: '/energiapalvelut',
    },
  ],
})

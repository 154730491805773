<template>
  <v-app light id="app">

    <template v-if="loadingOverlay">
      <v-fade-transition>
        <div class="loading-overlay">
          <v-row class="fill-height" justify="center" align="center">
            <v-col cols="4" class="text-center">
              <img style="width: 100px; height: auto; opacity: 0.4;" src="/img/logo.svg"/>
            </v-col>
          </v-row>
        </div>
      </v-fade-transition>
    </template>

    <v-snackbar v-model="snackbar" top multi-line :timeout="notification.timeOut" :color="notification.color">
      {{ notification.text }}
      <template v-slot:action>
        <template v-if="notification.showButton">
          <v-btn small outlined text @click="() => { notification.callback(); snackbar = false;}">
            {{ notification.buttonText }}
          </v-btn>
        </template>
        <template v-else>
          <v-btn icon @click="snackbar = false">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </template>
    </v-snackbar>

    <v-navigation-drawer
      v-model="menuOpenState"
      :mini-variant="miniMenu && $vuetify.breakpoint.lgAndUp"
      app
      fixed
      dark
      width="300"

    >
      <v-list class="pa-0 elevation-4 grey lighten-5" light>
        <v-list-item style="padding: 2px 10px;">
          <v-list-item-avatar color="White" @click.stop="toggleMiniMenu" style="margin-top: 10px; margin-bottom: 10px; margin-left: -2px;">
            <img style="height: 100%; width: auto;" src="/img/logo.svg" />
          </v-list-item-avatar>

          <v-list-item-content  style="padding-top: 11px; padding-bottom: 11px;">
            <v-list-item-title class="font-weight-bold mb-0">
              {{ $t('title') }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              icon
              @click.stop="toggleMiniMenu"
              small
            >
              <v-icon>chevron_left</v-icon>
            </v-btn>
          </v-list-item-action>

        </v-list-item>
      </v-list>

      <v-list :dense="$vuetify.breakpoint.mdAndDown">

        <template v-if="filteredPrimaryPages.length > 0">
          <v-list-item
            v-for="(page, index) in filteredPrimaryPages"
            :key="index+'primary'"
            :to="getPageLink(page)"
            @click="closeMenuIfOnSamePage(page.link)"
            exact
          >
            <v-list-item-action>
              <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ page.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </template>

        <template v-if="filteredSecondaryPages.length > 0">
          <v-list-item
            v-for="(page, index) in filteredSecondaryPages"
            :key="index+'secondary'"
            @click="closeMenuIfOnSamePage(page.link)"
            :to="getPageLink(page)"
            exact
          >
            <v-list-item-action>
              <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ page.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </template>

        <template v-if="filteredTenantAdminPages.length > 0">
          <v-divider></v-divider>
          <v-subheader v-if="!miniMenu">{{ $t('menu_subheaders.organization_admin') }}</v-subheader>
          <v-list-item
            v-for="(page, index) in filteredTenantAdminPages"
            :key="index+'tadmin'"
            @click="closeMenuIfOnSamePage(page.link)"
            :to="getPageLink(page)"
            exact>
            <v-list-item-action>
              <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ page.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </template>

        <template v-if="filteredAdminPages.length > 0">
          <v-subheader v-if="!miniMenu">{{ $t('menu_subheaders.application_admin') }}</v-subheader>
          <v-list-item
            v-for="(page, index) in filteredAdminPages"
            :key="index+'admin'"
            @click="closeMenuIfOnSamePage(page.link)"
            :to="getPageLink(page)"
            exact>
            <v-list-item-action>
              <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ page.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-list>

    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      notification: {
        color: '',
        text: '',
        buttonText: '',
        showButton: false,
        timeOut: -1,
        callback: () => {
        },
      },
      snackbar: false,
      miniMenu: false,
      bottomNavSelected: '',
      showBottomNav: true,
      menuOpenState: false,
      pages: [
        {
          title: this.$t('energy_services.title'),
          icon: 'fas fa-cog',
          primary: true,
          link: 'energy_services'
        },
      ],
      tenantPages: [
      ],
      adminPages: [
      ],
    }
  },
  methods: {
    getPageLink(page) {
      const link = {
        name: page.link,
      }

      if ('params' in page) {
        link.params = page.params();
      }
      return link;
    },
    goToLink(name) {
      this.$router.push({ name: name });
    },
    toggleMiniMenu() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.miniMenu = !this.miniMenu;
      } else {
        this.$store.commit('toggleMainMenuState');
      }
    },
    closeMenuIfOnSamePage(link) {
      if (this.$vuetify.breakpoint.mdAndDown && this.$route.name === link) {
        this.menuOpenState = false;
      }
    },
    handleSelectedBottomNavItem(name) {
      const isPagePrimary = this.pages.findIndex(p => p.primary && p.link === name) > -1;
      if (isPagePrimary) {
        this.bottomNavSelected = name;
        this.showBottomNav = true;
      } else {
        this.showBottomNav = false;
      }
    },
  },
  watch: {
    loadingOverlay(to) {
      if (to) {
        setTimeout(() => {
          this.$store.commit('setLoadingOverlay', false);
        }, 2000);
      }
    },
    notificationObject(to) {
      this.notification.color = to.color;
      this.notification.text = to.message;
      this.snackbar = true;
      this.notification.buttonText = to.buttonText;
      this.notification.showButton = to.showButton;
      this.notification.timeOut = to.timeOut;
      this.notification.callback = to.callback;
    },
    menuOpenComputed(to) {
      this.menuOpenState = to;
    },
    menuOpenState(to) {
      if (to !== this.$store.state.mainMenuOpen) {
        this.$store.commit('toggleMainMenuState');
      }
    },
    $route(to, from) {
      if (from && from.name) this.$store.commit('setPreviousRouteName', from.name);
      this.handleSelectedBottomNavItem(to.name);
    },
  },
  computed: {
    loadingOverlay() {
      return this.$store.state.loadingOverlay;
    },
    notificationObject() {
      return this.$store.state.notificationObject;
    },
    menuOpenComputed() {
      let menuState = true;
      if (this.$vuetify.breakpoint.mdAndDown) {
        menuState = this.$store.state.mainMenuOpen;
      }
      return menuState;
    },
    filteredSecondaryPages() {
      let pages = [];
      this.pages.forEach(page => {
        if (!page.primary) {
          pages.push(page);
        }
      })
      return pages;
    },
    filteredTenantAdminPages() {
      return this.tenantPages;
    },
    filteredAdminPages() {
      return this.adminPages;
    },
    filteredPrimaryPages() {
      let pages = [];
      this.pages.forEach(page => {
        if (page.primary) {
          pages.push(page);
        }
      })
      return pages;
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  mounted() {
    if (this.$vuetify.breakpoint.lgAndUp) this.menuOpenState = true;
    this.handleSelectedBottomNavItem(this.$route.name);
  },
}
</script>

<style lang="scss" scoped>
@import "./sass/commonstyles.scss";
</style>

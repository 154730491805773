import 'vuetify/dist/vuetify.min.css'
import Vue from 'vue'
import i18n from './i18n.js';
import vuetify from './plugins/vuetify.plugin.js';
import Axios from 'axios' // set defaults before any other imports

import HelperPlugin from './plugins/helper.plugin'
import ValidationPlugin from './plugins/validation.plugin'
import App from './App.vue'
import router from './router'
import store from './store'

import BackButton from './components/BackButton.vue';
import MainMenuButton from './components/MainMenuButton.vue';
import LoadingIndicator from './components/LoadingIndicator.vue';
import BasicPage from "@/components/BasicPage";

Vue.use(HelperPlugin);
Vue.use(ValidationPlugin);

Vue.component('hb-back-button', BackButton);
Vue.component('hb-main-menu-button', MainMenuButton);
Vue.component('hb-loading-indicator', LoadingIndicator);
Vue.component('hb-basic-page', BasicPage);

Axios.defaults.withCredentials = true;
Axios.defaults.headers.common['Pragma'] = 'no-cache';
Axios.defaults.headers.common['Cache-Control'] = 'no-cache, no-store';
Axios.defaults.headers.common['Content-Type'] = 'application/json';
Axios.defaults.headers.common['Accept'] = 'application/json';
Vue.config.productionTip = false;

async function init() {
  new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app');
}

init();

/* eslint-disable no-param-reassign,no-unused-vars */
import Vue from 'vue'
import moment from 'moment/moment'

import i18n from "../i18n";

moment.locale('fi');

const HelperPlugin = {
  install(/* vue */) {
    Vue.mixin({
      computed: {
        $baseUrl() {
          const hasCustomBaseUrl = process.env.VUE_APP_API_BASEURL.indexOf('undefined') === -1;
          if (hasCustomBaseUrl) {
            return process.env.VUE_APP_API_BASEURL;
          }
          return '';
        },
        $bottomNavigationStyle() {
          if (this.$vuetify.breakpoint.xsOnly) {
            return "z-index: 5; bottom: 72px"
          }
          return '';
        }
      },
    });
  },
};

export default HelperPlugin;

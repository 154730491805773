const messages = {
  // English
  en: {},
  // Finnish
  fi: {
    menu_title: 'Ohjeet',
    title: 'Pori Energia API',
    energy_services: {
      title: 'Energiapalvelut',
    }
  },
  // German
  de: {},
  // Swedish
  sv: {},
};

export default messages;

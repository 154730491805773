/* eslint-disable no-param-reassign */
import Vue from 'vue';

const ValidationPlugin = {
  install(/* vue */) {
    Vue.mixin({
      computed: {
        $rules() {
          return {
            required: value => !!value || this.$t('validation.field_is_required'),
            email: email => {
              let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return !!re.test(String(email).toLowerCase()) || this.$t('validation.email_is_required');
            },
            spacesNotAllowed: value => !(value && value.indexOf(" ") > -1) || this.$t('validation.spaces_are_not_allowed'),
            passwordHasToMatch: (value, match) => value === match || this.$t('user_profile.new_passwords_has_to_match'),
            mustBeAtLeast6Characters: value => !!value && value.length > 5 || this.$t('user_profile.password_must_be_at_least_6_characters'),
            keyRule: value => {
              const re = /^[^_-][a-z0-9-_]*[^_-]$/;
              return !!re.test(String(value)) || this.$t('subscriptions.key_hint');
            }
          };
        },
      },
    });
  },
};

export default ValidationPlugin;

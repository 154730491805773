<template>
  <hb-basic-page :loading="pageLoading" :fill-height="pageLoading" :title="$t('energy_services.title')">
    <rapi-doc class="pt-4"
        spec-url="/energiapalvelut/v1/Energiapalvelut API.openapi.yaml"
        render-style="view"
        style="height:100vh; width:100%"
        theme="light"
        show-header="false"
        show-info="true"
        allow-authentication="false"
        allow-server-selection="false"
        allow-api-list-style-selection="true"
        allow-try="false"
        schema-style="table"
        regular-font="'Avenir Next', sans-serif"
        info-description-headings-in-navbar="true"
        schema-description-expanded="true"
        primary-color="#50be00"
        on-nav-tag-click="show-description"
    >
    </rapi-doc>
  </hb-basic-page>
</template>

<script>
export default {
  name: "EnergyServicesApi",
  data() {
    return {
      pageLoading: true,
    }
  },
  methods: {

  },
  async mounted() {
    this.pageLoading = false;
  }
}
</script>

<style scoped>

</style>

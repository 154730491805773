import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#50be00',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
        warning: '#ff9800',
        info: '#40c4ff',
        success: '#66bb6a',
      },
      dark: {
        primary: '#50be00',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
        warning: '#ff9800',
        info: '#40c4ff',
        success: '#66bb6a',
      }
    }
  }
});
